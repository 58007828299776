import React from "react";
import {
  SEO,
  PageLayout,
  MarkdownContent,
  FilteredList,
  GroupedList,
  MultipleComponentIterator,
  SlideReveal,
  BackgroundImageTeamMember,
  InternalLink,
} from "@bluefin/components";
import { Grid, Header, Button, Card } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";
import { createTeamMemberSlug } from "@bluefin/gatsby-plugin-fisherman-page-creator";

export default class TeamPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessTeam,
      allFishermanBusinessLocation,
      allFishermanBusinessTeamGroup,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={false} className={"team-page"}>
          <Grid
            stackable={true}
            textAlign={"center"}
            className={"team-content-container"}
          >
            <Grid.Column width={16}>
              <Header
                as={"h1"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "stylists_header",
                  defaultValue: "Our Team",
                })}
              />
              <MarkdownContent
                className={"about-team-description"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "stylists_description",
                })}
              />
              <FilteredList
                items={allFishermanBusinessTeam.nodes}
                itemFilterKey={"locations.name"}
                childItemsKey={"items"}
                defaultFilterValue={"All Locations"}
                displayAllFilterValue={"All Locations"}
                filterValues={allFishermanBusinessLocation.nodes}
                filterValueKey={"name"}
                filterValuesComponent={<Button basic={true} />}
                className={"team-filtered-list"}
              >
                <GroupedList
                  groupBy={"teams.name"}
                  childItemsKey={"iterator"}
                  defaultGroupName={"Our Team"}
                  groupOrderOptions={{
                    defaultGroupPosition: "start",
                    orderKey: "name",
                    order: allFishermanBusinessTeamGroup.nodes,
                  }}
                  itemComponent={
                    <MultipleComponentIterator
                      components={[
                        {
                          component: (
                            <SlideReveal
                              className={"ui card team-member"}
                              fade={true}
                              direction={"up"}
                              triggerOnce={true}
                            />
                          ),
                          propMap: { key: "_id" },
                          children: [
                            {
                              component: (
                                <BackgroundImageTeamMember
                                  useModal={false}
                                  as={InternalLink}
                                />
                              ),
                              propMap: {
                                "teamMember.name": "name",
                                "teamMember.role": "role",
                                "teamMember.photo": "gatsbyImage",
                                "teamMember.description": "description",
                                "teamMember.email": "email",
                                "teamMember.phone": "phone",
                                "teamMember.locations": "locations",
                                "teamMember.hours": "hours",
                                "teamMember.social": "socialMedia",
                                to: createTeamMemberSlug,
                              },
                            },
                          ],
                        },
                      ]}
                    />
                  }
                  groupHeaderComponent={<Header as={"h2"} />}
                  groupContainerComponent={
                    <Card.Group
                      itemsPerRow={3}
                      className={"stylists"}
                      stackable={true}
                    />
                  }
                  groupDescriptionKey={"description"}
                />
              </FilteredList>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Team" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
        contentNodes {
          content
        }
      }
    }
    allFishermanBusinessTeam(
      sort: { order: ASC, fields: order }
      filter: { show: { eq: true } }
    ) {
      nodes {
        _id
        name
        role
        group
        email
        phone
        description
        image
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        socialMedia {
          platform
          url
        }
        hours {
          startDay
          openTime
          closeTime
        }
        teams {
          _id
          name
          description
        }
        locations {
          _id
          name
        }
        show
      }
    }
    allFishermanBusinessLocation {
      nodes {
        _id
        name
      }
    }
    allFishermanBusinessTeamGroup(sort: { order: ASC, fields: order }) {
      nodes {
        name
      }
    }
  }
`;
